<template>
    <v-container
        class='orders_container'
        v-if="orders && !loading"
    >
        <span
            class='watermark'
            v-if="orders_count == 0"
        >
            <h1>{{$t('orders.noorder')}}</h1>
            <v-btn
                to="/"
                class='mt-10'
                color='primary'
                fab
                large
                elevation="3"
            >
                <v-icon>mdi-home</v-icon>
            </v-btn>
        </span>
        <template v-else>
            <v-card
                v-for="(section_orders, name) in filtered_lists"
                :key="name"
                elevation="0"
                class='mb-10'
            >
                <v-card-title>
                    <v-icon class='mr-5'>mdi-{{icons[name]}}</v-icon>{{name}}
                </v-card-title>
                <v-expansion-panels v-model="opened[name]">
                    <v-expansion-panel
                        v-for="(order,index) in section_orders"
                        :key="index"
                    >
                        <v-expansion-panel-header v-slot="{ open }" :id="'order_'+order.id">
                            <v-card-title class="mb-2">
                                {{$t('orders.order')}}&nbsp;<span style="opacity:0.7">{{order.id}}</span>&nbsp;du {{$utils.format(order.createdDate)}}
                                &nbsp;&nbsp;<b>{{order.items.length}} {{$tc('orders.booking', order.items.length)}}</b>
                                <div
                                    v-for="(item,item_id) in order.items"
                                    v-if="!open"
                                    :key="item_id"
                                    style='font-size:15px; font-weight:normal'
                                >
                                  &emsp;{{item.label}}
                                </div>
                            </v-card-title>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list>
                                <div
                                    v-for="(item,item_id) in order.items"
                                    :key="item_id"
                                    class='mb-10'
                                >
                                    {{item.label}}
                                    <product
                                        :product="item.product"
                                        :selected="comming(order) && !refunded(item)"
                                        :selectable="comming(order) && !refunded(item)"
                                        class='ma-3'
                                        :color='comming(item) ? "primary" : ""'
                                        :elevation="comming(order) && !refunded(item)? 10 : 2"
                                        :disabled="refunded(item)"
                                        :to="comming(order) && !refunded(item)? '/reservation/?item_id='+item.id : null"
                                        :price_text="refunded(item) ? $t('refunded') : null"
                                    ></product>
                                </div>
                            </v-list>
                            <v-card-title style="font-size:30px">
                                <v-divider class='mr-5'></v-divider>{{get_order_price(order)}} €
                            </v-card-title>
                            <v-alert
                                v-if="cancel_error[order.id]"
                                type="error"
                            >{{cancel_error[order.id]}}</v-alert>
                            <v-card-title>
                                <v-btn
                                    v-if="cancelable(order)"
                                    right
                                    color="error"
                                    text
                                    large
                                    @click="prepare_delete_order(order)"
                                    :disabled="deleting_order == order.id"
                                    :loading="deleting_order == order.id"
                                >
                                    <v-icon class='mr-3'>mdi-delete</v-icon> {{$t('orders.cancelorder')}}
                                </v-btn>
                                <v-spacer />

                                <!-- ---------------------------------------- URL BTNS -->

                                <v-menu v-if="btn_urls(order).length > 0">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            :href="btn_urls(order).length == 1 ? btn_urls(order)[0].url : null"
                                            target="_blank"
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                            text
                                        >
                                            <v-icon class='mr-3'>mdi-receipt</v-icon>{{stripe_btn_name(order)}}
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="btn_data in btn_urls(order)"
                                            :key="btn_data.url"
                                        >
                                            <v-btn
                                                :href="btn_data.url"
                                                target="_blank"
                                                text
                                            >{{btn_data.name}}
                                            </v-btn>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                            </v-card-title>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>
        </template>
        <v-dialog
            v-model="open_cancel_order"
            max-width="400"
        >
            <v-card
                v-if="order_to_candel"
                class='pa-5'
            >
                <v-card-title class="text-h5">
                  {{$t('orders.cancelorder')}}&nbsp;<u>{{order_to_candel.id}}</u> ?
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="open_cancel_order=false;order_to_candel=null"
                    >
                      {{$t('orders.canceltext')}}
                    </v-btn>
                    <v-btn
                        color="error"
                        text
                        @click="delete_order(order_to_candel.id);open_cancel_order=false;order_to_candel=false"
                    >
                      {{$t('orders.cancelorder')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
    <v-container v-else>
        <v-skeleton-loader
            height="1000px"
            type="list-item@5"
            :loading="true"
        ></v-skeleton-loader>
    </v-container>
</template>

<script>
import product from '../components/product.vue'
import { mapActions } from 'vuex'
/*
@desc Full orders user page
*/
export default {
    components: { product },
    data: () => ({
        orders: [],
        deleting_order: null,
        order_to_candel: null,
        open_cancel_order: false,
        cancel_error: {},
        order_to_open: null,
        loading: true,
    }),
    watch: {
        /*
        @desc Check for new order to trigger the "which one need to be opened" checker
        */
        orders: {
            handler() {
                setTimeout(() => this.open_order(this.order_to_open, this.$t('orders.pending')), 500)
            },
            deep: true
        },
    },
    computed: {
        comming_orders() {
            return this.orders?.filter(o => o.status == 'paid' && !o.past)
        },
        past_orders() {
            return this.orders?.filter(o => o.status == 'paid' && o.past)
        },
        canceled_orders() {
            return this.orders?.filter(o => ['refunded', 'canceled'].includes(o.status))
        },
        waiting_orders() {
            return this.orders?.filter(o => o.status == 'billed')
        },
        /*
        @desc Orders grouped in sub lists
        */
        orders_lists() {
            return {
              [this.$t('orders.pending')]: this.comming_orders,
              [this.$t('orders.billed')]: this.waiting_orders,
              [this.$t('orders.past')]: this.past_orders,
              [this.$t('orders.canceled')]: this.canceled_orders,
            }
        },
        /*
        @desc Orders filtered by items (no item orders are not shown)
        */
        filtered_lists() {
            return Object.fromEntries(Object.entries(this.orders_lists).filter(([, l]) => l.length))
        },
        orders_count() {
            return this.orders.length
        },
        opened () {
          return {
            [this.$t('orders.pending')]: null,
            [this.$t('orders.canceled')]: null,
          }
        },
        icons() {
          return {
            [this.$t('orders.pending')]: 'star-shooting',
            [this.$t('orders.billed')]: 'clipboard-text-clock',
            [this.$t('orders.past')]: "check-bold",
            [this.$t('orders.canceled')]: 'clipboard-off',
          }
        },
    },
    methods: {
        ...mapActions(['set_back_state']),
        /*
        @desc Is the given order billed and waiting for payement
        */
        waiting(order) {
            return order.status == 'billed'
        },
        comming(order) {
            return order.status == 'paid' && !order.past
        },
        refunded(order) {
            return order.status == 'refunded'
        },
        cancelable(order) {
            return !this.$utils.time_is_in_past(order.items[0].startDate) && !(order.status == 'refunded' || order.status == 'canceled')
        },
        /*
        @desc Compute full order price (take into account refunded items)
        */
        get_order_price(order) {
            return order.items.map(item => item.status != 'refunded' ? item.price : 0).reduce((a, b) => a + b, 0) / 100
        },
        prepare_delete_order(order) {
            this.open_cancel_order = true
            this.order_to_candel = order
        },
        async delete_order(id) {
            this.$delete(this.cancel_error, id)
            try {
                this.deleting_order = id
                await this.$api.user.booking.delete(id)
                this.deleting_order = null
                this.reload()
            } catch (e) {
                this.cancel_error[id] = this.$t('orders.cancelerror')
                this.deleting_order = null
            }
        },
        /*
        @desc Open specific order in UI
        @arg1 order ID
        @arg2 section under which to find the order
        */
        open_order(order_id, section) {
            const index = this.comming_orders.map(order => order.id + '').indexOf(order_id + '')
            this.opened[section] = index
            setTimeout(() =>
                document.getElementById('order_' + order_id)?.scrollIntoView({ behavior: 'smooth' }), 500)
        },
        stripe_btn_name(order) {
            return {
                'billed': this.$t('orders.pay'),
                'paid': this.$t('orders.receipt'),
                'refunded': this.$t('orders.refundingreceipt')
            }[order.status] ?? this.$t('orders.receipt')
        },
        btn_urls(order) {
            const invoices = order.invoices ?? []
            const stripe_url = order.stripeUrl
            const urls = invoices.map((url, i) => ({ name: i == 0 ? this.$t('orders.invoice') : this.$t('orders.refunding'), url }))
            if (stripe_url && stripe_url.includes('checkout.stripe.com'))
                urls.push({ name: this.$t('orders.pay'), url: stripe_url })
            return urls
        },
        /*
        @desc Load full orders array from API
        */
        reload() {
            this.loading = true
            this.$utils.get_paged_data((page) => this.$api.user.booking.all(page)).then(orders => {
                this.loading = false
                const final_orders = this.$utils
                    .sort_by_date(orders
                        .filter(o => o.items.length)
                        .map(o => {
                            const item_status = o.items.map(i => i.status).filter((e, i, s) => s.indexOf(e) == i)
                            if (item_status.length == 1 && item_status[0] == 'refunded') {
                                o.status = 'refunded'
                            }
                            return { ...o, past: this.$utils.time_is_in_past(o.items[0]?.endDate) }
                        }),
                        'items.0.startDate')

                this.$set(this, 'orders', final_orders)
                if (this.comming_orders.length == 1) {
                    this.order_to_open = this.comming_orders[0].id
                }
                this.set_back_state(this.orders_count ? 'contains' : 'empty')
            })
        }
    },
    /*
    @desc Launch full orders loading method `reload`
    */
    mounted() {
        this.reload()
        this.order_to_open = this.$utils.get_url_args().order_id
    }
}
</script>

<style>
.orders_container {
    max-width: 100%;
    width: 1000px !important;
}
</style>