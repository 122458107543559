<template>
    <div style="position: relative">
      <v-row>
        <v-col
            v-for="(type) in product_types"
            :key="index"
            :sm="products.length == 1"
            cols="12"
        >

          <div id="container">

            <div class="product-image">

              <img  :src="type.imageUrl" alt="">
            </div>
            <div class="product-details">
              <div id="title-price">
                <h1>{{type.type}}</h1>
                <p
                    :class='["price",selected ? "selected" : ""]'
                    :style="{background:(selected || color) ? 'var(--v-'+(color ? color : 'secondary')+'-base)' : '#fff'}">
                  {{type.price/100+$t('product.pricetxt')}}
                </p>
              </div>
              <p class="information">{{$t('product-withoutlist.available') + available_products_count(type.type)}}/{{products_count(type.type)}}</p>
              <v-btn
                  v-show="available_products_count(type.type) && !selected_product_checker(selected_product(type.type)) && !in_cart_overlay(selected_product(type.type))"
                  type="checkbox"
                  color="secondary"
                  id="addcartbutton"
                  :selected="selected_product_checker(selected_product(type.type))"
                  :class='selected ? "selected" : ""'
                  @click="$emit('select',selected_product(type.type))"
              >
                <p
                    id="buttontext"><v-icon class='mr-3 ml-0'>mdi-plus</v-icon>{{$t('product-withoutlist.addcart')}}
                </p>
              </v-btn>

              <v-btn
                  v-show="available_products_count(type.type) && selected_product_checker(selected_product(type.type))"
                  type="checkbox"
                  color="error"
                  id="addcartbutton"
                  :selected="selected_product_checker(selected_product(type.type))"
                  :class='selected ? "selected" : ""'
                  @click="$emit('select',selected_product(type.type))"
              >
                <p
                    id="buttontext"><v-icon class='mr-3 ml-0'>mdi-minus</v-icon>{{$t('product-withoutlist.removecart')}}
                </p>
              </v-btn>

              <v-btn
                  v-show="!available_products_count(type.type)"
                  type="checkbox"
                  color="primary"
                  id="addcartbutton"
                  :disabled=true
                  :selected="selected_product_checker(selected_product(type.type))"
                  :class='selected ? "selected" : ""'
              >
                <p
                    id="buttontext">{{$t('product-withoutlist.unavailable')}}
                </p>
              </v-btn>

              <v-btn
                  v-show="in_cart_overlay(selected_product(type.type)) && available_products_count(type.type) && !selected_product_checker(selected_product(type.type))"
                  type="checkbox"
                  color="primary"
                  id="addcartbutton"
                  :disabled=true
                  :selected="selected_product_checker(selected_product(type.type))"
                  :class='selected ? "selected" : ""'
              >
                <p
                    id="buttontext">{{$t('product-withoutlist.incart')}}
                </p>
              </v-btn>

            </div>
          </div>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import product from './product.vue'
import { mapState } from 'vuex'
import {find} from "core-js/internals/array-iteration";
/*
@desc Product list to display in search page
*/
export default {
    props: ['selected_area', 'selected_product_checker', 'filter_dates', 'error', 'color', 'selected'],
    components: { product },
    computed: {
        ...mapState(['cart']),
        products() {
            return Object.values(this.selected_area.products)
        },

        selected_product() {
          return (type) => {
            const av_products = Object.values(this.selected_area.products).filter(product => product.activated !== false && product.available !== false && product.type === type)
            const reserved_products = Object.values(this.selected_area.products).filter(product => product.activated !== false && product.available == false && product.type === type)
            const reference = 0

            //Function to calculate the actual total weight. It adds the weights of the reserved places and return the total
            //test is an array of the reserved places ; weight_tab an array with all the places and their weight
            //returns a total_weight (int)
            function calculateTotalWeight(reserved_products) {
              let total_weight = 0
              for (const place of reserved_products) {
                total_weight += place.meta.weight
              }
              return total_weight
            }

            //Function to calculate the better place to chose to approach an actual total weight corresponding to the reference number
            //free_places is an array of the actual free places and their weight ; reserved_places is an array of the actual reserved places ; reference is the total weight goal
            //returns the best weight (pos or neg int)
            function getBetterWeight(free_places, reserved_places, reference) {
              const actual_weight = calculateTotalWeight(reserved_places)
              let test_weight = null

              for (const place of free_places) {
                if (place.meta.weight === reference) {
                  return place.meta.weight
                }
                if (test_weight === null || (Math.abs(reference - (test_weight + actual_weight)) > Math.abs((place.meta.weight + actual_weight) - reference))) {
                  test_weight = place.meta.weight
                }
              }
              return test_weight
            }

            //Function that returns the place associate with the test_weight
            //better_weight is the better test_weight found, av_products is an array of the available places
            //returns the place associate with the test weight (array)
            function getPlaceAssociateToBetterWeight(better_weight, av_products) {
              let better_weighted_place = []
              better_weighted_place = av_products.find(place => place.meta.weight === better_weight)
              return better_weighted_place
            }

            return getPlaceAssociateToBetterWeight(getBetterWeight(av_products, reserved_products, reference), av_products)
          }
        },

      product_types() {
        const products = Object.values(this.selected_area.products);
        const typesSet = new Set();

        products.forEach(product => {
          typesSet.add(product.type);
        });

        const obj_types = Array.from(typesSet).map(type => {
          const productOfType = products.find(product => product.type === type);
          return {
            type: type,
            imageUrl: productOfType.imageUrl,
            price: productOfType.price
          };
        });

        return obj_types;
      }
    },

    methods: {
        in_cart_overlay(product) {
            const in_cart_pds = this.cart.filter(pc => pc.product_id == product.id)
            if (!in_cart_pds.length) return false
            const filter_dates = this.filter_dates
            return in_cart_pds.some(in_cart => {
                const cart_dates = [new Date(in_cart.start_date * 1000), new Date(in_cart.end_date * 1000)];
                return (
                    (cart_dates[0] >= filter_dates[0] && cart_dates[0] <= filter_dates[1]) ||  // Check if filter start date is within cart item dates
                    (cart_dates[1] >= filter_dates[0] && cart_dates[1] <= filter_dates[1]) ||  // Check if filter end date is within cart item dates
                    (cart_dates[0] <= filter_dates[0] && cart_dates[1] >= filter_dates[1])     // Check if filter dates are completely within cart item dates
                );
            });
        },

      products_count(type) {
        const type_products = Object.values(this.selected_area.products).filter(product => product.type == type)
        return type_products.length
      },

      available_products_count(type) {
        const av_products = Object.values(this.selected_area.products).filter(product => product.activated !== false && product.available !== false && product.type == type)
        return av_products.length
      },

      getPositiveResultFound(array, nearest) {
        return(array.find(weight => weight === Math.abs(nearest)) ?? nearest)
      },
    }
}
</script>

<style>

.price {
  text-align: right;
  font-weight: bold;
  font-size: 20px;
  background: #fff;
  padding: 5px 5px;
  border-radius: 100px;
  float:right;
}

#container {
  box-shadow: 0 15px 30px 1px grey;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  margin: 0em auto;
}
@media only screen and (min-width: 901px) {
  #container {
    height: 230px;
    max-width: 90%;
  }
  .product-image {
    height: 100%;
    float: left;
    width: 30%;
  }

  #container img {
    height: 100%;
  }

  #addcartbutton {
    float:right;
  }

  .product-details {
  text-align: left;
  padding: 30px;
  float: left;
  width: 70%;
  }

  #container .product-details > p {
    text-align: left;
  }
}
@media only screen and (max-width: 900px) {
  #container {
    max-width: 95%;
    height: 300px;
    margin-top: 30px;
  }

  .product-image {
    height: 30%;
    float: top;
    width: 100%;
  }

  #container img {
    width: 100%;
  }

  #addcartbutton {
    float:bottom;
    margin-top:10px;
  }

  .product-details {
    width: 95%;
    margin:auto;
  }

  #title-price h1 {
    float:left;
    padding-left:5px;
  }

  .information {
    margin-top: 50px;
    margin-left: 5px;
    text-align: left;
  }
}

.product-image {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

#container .product-details h1 {
  font-size: 25px;
  color: #303030;
}

.product-details {
  overflow: hidden;
  height: 100%;
}

#container .product-details > p {
  font-size: 18px;
  color: #7d7d7d;
}

#container .price {
  color: #303030 !important;
}

#addcartbutton {
  background-color: #119e8c;
  border-radius: 5px;
  padding: 2rem;
  display: flex;
  margin: auto;
}

#title-price h1, #title-price p {
  display: inline;
  vertical-align: top;
}

#addcartbutton p {
  margin-bottom: 0px !important;
}

</style>
