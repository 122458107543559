<template>
    <v-app
        :class="$is_mobile ? 'mobile' : ''"
        :style="{background: $vuetify.theme.themes[theme].background}"
    >
        <!-- ---------------------------------- BACK -->
<!--        <back-illustrator></back-illustrator>-->
        <!-- ---------------------------------- HEADER -->
        <common-header></common-header>
        <!-- ---------------------------------- MAIN ROUTER -->
        <v-main style="padding-bottom:200px">
            <v-container fill-height>
                <v-row>
                    <v-col>
                        <router-view />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <!-- ---------------------------------- FOOTER -->
        <common-footer></common-footer>
    </v-app>
</template>

<script>
import CommonHeader from './components/common-header.vue'
import BackIllustrator from '@/components/back-illustrator.vue'
import CommonFooter from '@/components/common-footer.vue'
/*
@description Main application entry point
*/
export default {
    name: 'App',
    components: { CommonHeader, BackIllustrator, CommonFooter },
    data: () => ({
        times: [],
        datetimes: []
    }),
    computed:{
      theme(){
        return (this.$vuetify.theme.dark) ? 'dark' : 'light'
      }
    },
    watch: {
        /*
        @desc Scroll top when route changes
        */
        '$route'() {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    },
    methods: {
      //Function to add a button to toggle dark mode
      toggle_dark_mode: function() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
      }
    },
    mounted() {
      const theme = localStorage.getItem("dark_theme");
      if (theme) {
        if (theme === "true") {
          this.$vuetify.theme.dark = true;
        } else {
          this.$vuetify.theme.dark = false;
        }
      } else if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        this.$vuetify.theme.dark = true;
        localStorage.setItem(
            "dark_theme",
            this.$vuetify.theme.dark.toString()
        );
      }
    },
}
</script>


<style>
body,
.v-card__title {
    word-break: break-word !important;
}
</style>
