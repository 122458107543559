<template>
    <div>
        <home-infos @click="change_bool()"></home-infos>
        <product-searcher v-if="show_reservation || from_header()===true"></product-searcher>
    </div>
</template>

<script>
import ProductSearcher from '@/components/product-searcher.vue'
import HomeInfos from '@/components/home-infos.vue'
import {mapGetters} from "vuex";
import {from_header} from "@/main";

/*
@desc Main Home page (booking search, wrapper for `product-searcher` and `home-infos` component)
*/
export default {
    name: 'Home',
    methods: {
        from_header() {
            if (from_header === true) this.show_reservation = true
            return from_header
        },
        change_bool(){
            if (from_header === true) from_header = false
            this.show_reservation = !this.show_reservation
        }
    },
    components: { ProductSearcher, HomeInfos },
    data: () => ({
        show_reservation: false,
    }),
    computed: {
        ...mapGetters(['cart_count']),
    }
}
</script>
